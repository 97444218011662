<template>
    <h2>NORTHWEST CSIS T-SHIRT CONTEST WINNER</h2>
    <div class="content">
        <h1>REQUIREMENTS</h1><br>
        <p>-Your design should incorporate themes and concepts appropriate to computer science<br>
        -It is not required, but you are welcome to utilize the Northwest Logo and other tools available online at University Marketing and Communications.<br>
        -You are limited to a two color design which will be printed on a color t-shirt</p><br>
        <h1>THOUGHTS</h1><br>
        <p>At first I was hung up on how to create a design that pertained to computer science while being simple and easy to read. I kept going back to the too familiar "computer sciencey" 
        designs, such as circuit boards, Matrix-esque numbers, and peripherals like the keyboard. Luckily I realized I could use actual computer science to help design my shirt. I settled 
        on running a simple design through one of my image manipulation programs that I coded in Processing. I could then make a shirt that purely represented computer science - it was 
        made using it!</p><br>
        <h1>WHAT DID I LEARN?</h1><br>
        <p>This contest was kind of eye opening for me, because it was the first instance where I could combine my hobby (creative coding) and product design. It opened up a lot of thoughts and possibilities
            on what could be accomplished with generative art.</p><br>
        <h1 class="center">INITIAL DESIGNS USING ADOBE ILLUSTRATOR</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/csis1.png"></div>
        </div><br>
        <h1 class="center">IMAGES RAN THROUGH AN IMAGE MANIPULATION PROGRAM USING PROCESSING</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/csis2.png"></div>
        </div><br>
        <h1 class="center">FINAL TOUCH UP USING PHOTOSHOP</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/csis3.png"></div>
        </div><br>
        <h1 class="center">T-SHIRT</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/csiscontest.png"></div>
            <div><img src="../assets/csis4.jpg"></div>
        </div><br>
    </div><br><br>
</template>